$primary: #0984e3;
$secondary: #636e72;

$clear: #f8f6f6;
$dark: #b4b4b4;

$red: #f5222d;
$blue: #1890ff;
$cyan: #13c2c2;
$green: #C0C867;

