.text-writer-component {
    @keyframes blink {
        from { opacity: 1 }
        to { opacity: 0 }
    }

    .text::after {
        content: "|";
        animation: blink 1s infinite;
    }
}