@import '~antd/dist/antd.css';
@import '../Styles/_variables.scss';

.app {
  padding: 30px 50px;
  background-color: $clear;
}

.ant-col {
  &:not(.col-menu) {
    border-radius: 5px;
    padding: 20px 50px;
  }
}

.col-menu {
  text-align: center;
}

.language {
  font-style: italic;
}

.red {
  color : $red;
}

.blue {
  color: $blue;
}

.cyan {
  color: $cyan;
}