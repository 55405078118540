@import '../../Styles/_variables.scss';

.profile-component {
  display: flex;

  .ant-avatar {
    margin-bottom: 50px;
  }

  .logos {
    text-align: center;

    a {
      display: block;
      margin-bottom: 20px;
      color: $primary;
    }
  }

  .content {
    padding: 0 0 0 50px;
    font-size: 21px;
  }
}