@media screen and (max-width: 992px) {
  .profile-component {
    display: block;
    text-align: justify;

    .avatar {
      display: none;
    }

    .logos {
      display: flex;
      margin-top: 30px;
      justify-content: center;

      a:last-child {
        margin-left: 20px;
      }
    }

    .content {
      padding: 0px;
    }
  }
}