@import '../../Styles/_variables.scss';

.card-component {
  position: relative;
  height: auto;
  width: 100%;
  margin-left: 5px;

  .caret {
    position: absolute;
    top: 5px;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 15px solid transparent;

    border-right:10px solid $primary;
  }

  .card {
    padding: 15px;
    border-radius: 0 0 5px 5px;
    border: 1px solid $primary;
    border-top: none;
  }

  h2 {
      margin: 0;
      padding: 7px 15px;
      border-radius: 5px 5px 0px 0px;
      background-color: $primary;
      color: $clear;
    }

    .tags {
      text-align: right;
      border-top: 1px solid $secondary;
      margin-top: 10px;
      padding: 10px 0px 0px 0px;

      .ant-tag {
        margin: 0px 0px 5px 5px;

      }
    }
}