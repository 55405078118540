@import '../../Styles/_variables.scss';

.language-selector-component {
  position: relative;
  margin-top: -15px;

  .content {
    width: 115px;
    margin: auto;

  }

  .christmas-hat {
    position: absolute;
    z-index: 999;
    margin-top: -15px;
    width: 120px;
    height: 120px;
    display: none;
  }

  .icon-language-selector {
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-size: 80px !important;
    color: $primary;
    border-radius: 50%;
  }

  .icon-surrounder {
    margin: auto;
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 15px;
    border-radius: 60px;
    background-color: $clear;
  }

  .lang-list-surrounder {
    max-height: 0;
    width: 100px; // 80px img + 20px padding left/right surrounder
    margin: auto;
    margin-top: -50px;
    padding: 0;
    overflow: hidden;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    box-shadow: 3px 3px 3px $dark;

    transition-property: max-height, padding-top, padding-bottom;
    transition-duration: 1s, 1s, 1s;
  }

  .content:hover .lang-list-surrounder {
    max-height: 200px;
    padding-top: 60px;
    padding-bottom: 40px;
  }

  ul {
    padding: 0; // Remove the default padding on the left side
    list-style-type: none;
    z-index: 1;

    li {
      display: inline-block;
      text-align: center;
      border-bottom: 1px solid $clear;
      width: 100%;

      &:not(:last-child) {
        border-top: 1px solid $clear;
      }

      &:hover {
        cursor: pointer;
        color: $primary;
      }
    }
  }
}